.AgencyDashboard_panel {
    background-color: var(--panel-background);
    flex: 1;
    padding: 20px;
    overflow-x: auto;
    
}

.AgencyDashboard {
    display: flex;
}

.top-nav{
    background-color: var(--blue-400);
    flex: 1;
    height: 60px;
    position: relative;
    box-shadow:  0 4px 3.94px 0.06px rgb(4 26 55 / 16%);
}
.mobile-navigation-hamburger{
    font-size: 35px;
    margin-left: 10px;
    cursor: pointer;
    color: antiquewhite;
    display: none;
    visibility: hidden;
}

.AgencyDashboard-info-cards {
    display: flex;
    flex-wrap: wrap;
    gap:30px;
    
}


/* DisplayAgencyProfile */
.display-company-data{
    display: flex;
    padding-bottom: 20px;
}

.display-info{
    flex: 1;
    font-weight: 500;
}
.display-info-2{
    flex: 2;
    font-weight: 500;
}

.display-data{
    flex: 2;
}

/* buttons side by side or single */

.button-flex{
    width: 100px;
    margin-right: 20px;
}
.button-flex-lg{
    width: 150px;
    margin-right: 20px;
}

/* Displaysites Header */
.displaysites-header{
    display: flex;
}
.displaysites-header h2{
    flex: 1;
  }

/* think border for additional data box */
.thin-border{
    border: 1px solid #dee2e6;
    padding: 15px;
}


/* reports */
.ot-input{
    height: 38px;
    width: 50px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 4px 8px;
}
.reports-stats-panel{
    min-height: 150px;
}
.reports-stats-panel .card{
    min-width: 230px;
}
.reports-invoice-status-paid{
    
        background-color: rgba(25, 135, 84, 0.8);
        border-radius: 0.375rem;
        box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
        color: white;
        padding: 2px 10px 2px 10px;
}
.reports-invoice-status-pending{
    
    background-color: rgba(255, 193, 7, 0.6);
    border-radius: 0.375rem;
    box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
    color: white;
    padding: 2px;
}
.reports-invoice-status-open{
    
    background-color: rgba(255, 193, 7, 0.6);
    border-radius: 0.375rem;
    box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
    color: white;
    padding: 2px 10px 2px 10px;
}
.reports-invoice-status-canceled{
    
    background-color: rgba(245, 125, 88, 0.6);
    border-radius: 0.375rem;
    box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
    color: white;
    padding: 2px;
}

/* invoice */
