* {
  box-sizing: border-box;
}

:root {
  --blue-900: #1e3a8a;
  --blue-800: #1e40af;
  --blue-700: #1d4ed8;
  --blue-600: #2563eb;
  --blue-500: #3b82f6;
  --blue-400: #60a5fa;
  --blue-300: #93c5fd;
  --panel-background: #f4f7fa;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;

  padding: 0;

  font-family: "Montserrat", sans-serif;

  color: black;

  background-color: white;
}

/* general css */
.card {
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  margin-bottom: 30px;
  flex: 1;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  min-width: 200px;
  border: none !important;
}

.custom-width-modal {
  margin-top: 70px !important;
  min-width: 70%;
}
.custom-width-modal .custom-mondal-header {
  padding: 0 2rem 0 2rem;
}
.custom-width-modal .modal-content .modal-body .card {
  box-shadow: none;
  margin-bottom: 0;
  flex: 1;
  transition: all 0.3s ease-in-out;
  padding: 2rem;
  min-width: 200px;
  border: none;
}
.card a {
  text-decoration: none;
}
.sites-card-header {
  min-height: 250px;
  min-width: 350px !important;
}
.sites-card-header-2 {
  grid-column: 1 / 3;
}

.card:hover {
  box-shadow: 0 0 25px -5px #9e9c9e;
}

/* Grid */
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

/* site subheadings */
.display-flex {
  display: flex;
}
.flex-1 {
  flex: 1;
  align-items: center;
  gap: 15px;
}

/* display shift bar */
.shift-shiftbar {
  background-color: #e2e3e5;
  width: 100%;
  height: 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shift-timing {
  position: absolute;
  top: 0;
  /* left: 49.9999999997%; */
  /* background-color: #f8d7da; */
  height: 50px;
  /* width: 25%; */
  overflow: hidden;
  /* border-left: 4px solid #f5c2c7;
  border-right: 4px solid #f5c2c7; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.shift-open {
  background-color: #dc3545;
}
.shift-covered {
  background-color: #198754;
}
.shift-broadcasted {
  background-color: #ffc107;
}
.shift-previous {
  background-color: #6c757d;
}
.shift-clocked {
  background-color: var(--blue-400);
  border-left: 4px solid #534f4f;
  border-right: 4px solid #534f4f;
  opacity: 0.8;
}
.shift-hide {
  display: none;
}

.shift-display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  gap: 20px;
}
.shift-display-flex-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  gap: 20px;
  color: rgb(175, 37, 37);
}
.shift-container:hover {
  box-shadow: 0 3px 4.94px 3.06px rgb(4 26 55 / 16%);
  cursor: pointer;
}

.shift-display-data {
  min-width: 100px;
  padding-left: 10px;
}

.shift-details-containter {
  margin-left: 120px;
  display: flex;
}

.shift-details-containter > * {
  flex: 1;
}
.bg-active-style {
  background-color: rgba(25, 135, 84, 0.8);
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  color: white;
  padding: 2px 10px 2px 10px;
}

.bg-inactive-style {
  background-color: rgba(245, 125, 88, 0.6);
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  color: white;
  padding: 2px 10px 2px 10px;
}
.bg-available-style {
  background-color: rgba(25, 135, 84, 0.8);
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  color: white;
  padding: 2px;
}

.bg-not-available-style {
  background-color: rgba(245, 125, 88, 0.6);
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  color: white;
  padding: 2px;
}
/* users availablity box */
.availability-flex {
  display: flex;
}

.availability-flex > * {
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-weight: 500;
}

.user-profile-picture {
  width: 250px;
  height: 250px;
}

.user-profile-picture img {
  width: 250px;
  height: 250px;
}

.top-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.top-profile-picture img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.header-logo {
  color: antiquewhite;
  font-size: 32px;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
.header-logo-dashboard {
  color: antiquewhite;
  font-size: 32px;
  padding-left: 25px;
}
.header-logo-dashboard .logo-text {
  font-weight: 500;
}

.site-logo {
  height: 26px;
  transition: transform 0.7s ease-in-out;
  /* animation:spin 10s ease-in-out infinite; */
}

/* @keyframes spin { 
  0% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(0);
  }
  90% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.site-logo:hover {
  transform: rotate(360deg);
}

.header-logo .logo-text {
  font-weight: 500;
}
.profile-dropdown {
  color: antiquewhite;
}
/* add margin on profile-dropdown menu */
.dropdown-menu.show.dropdown-menu-light {
  margin-top: 15px;
}

/* top navbar icons */
.top-navbar-icon-style {
  display: flex;
  align-items: center;
  padding: 0 12px 0 12px;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.top-navbar-icon-style svg {
  font-size: 30px;
}

.top-navbar-icon-style:hover {
  background-color: var(--blue-500);
  transition: all 250ms;
}

/* dashboard cards */
.user-card-logo {
  background-color: rgba(245, 125, 88, 0.6);
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
}

.broadcasted-card-logo {
  background-color: rgba(255, 193, 7, 0.6);
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
}

.user-card-badge {
  background-color: rgba(25, 135, 84, 0.8);
  color: white;
  padding: 2px 8px;
  text-align: center;
  border-radius: 0.375rem;
}

.user-card-bottom-area-text {
  font-size: 14px;
  margin-bottom: -5px;
}
.dashboard-dacteselector {
  position: absolute;
  top: 0px;
  right: 100%;
}

.user-card-logo-dropdown {
  position: relative;
}
.line {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #987;
  vertical-align: middle;
  margin-left: 5px;
  opacity: 0.25;
}
.dashboard-chart-text-1 {
  font-size: 14px;
  margin-right: 2px;
}

.dashboard-arrow {
  cursor: pointer;
  margin-top: -1px;
}

.cursor-pointer {
  cursor: pointer;
}
.min-content {
  min-width: min-content !important;
}
.dashboard-arrow:hover {
  color: blue;
}

/* user schedule display*/
.display-site-schedule-container {
  min-width: 800px;
}
.date-cards-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1600px;
}

.date-cards-container .datecard {
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  transition: all ease-in-out 100ms;
  color: white;
  overflow: hidden;
}
.date-cards-container .datecard .schedule-scoll {
  overflow-x: hidden;
  overflow-y: auto;
}
.date-cards-container .datecard .schedule-scoll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}
.date-cards-container .datecard .schedule-scoll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
}
.date-cards-container .datecard .schedule-scoll::-webkit-scrollbar {
  width: 12px;
}
.date-cards-container .datecard a {
  color: white;
}

.date-cards-container .datecard:hover {
  scale: 103%;
}

.date-cards-container .shift-previous {
  background-color: rgba(143, 141, 141, 0.5);
}
.date-cards-container .display-timings {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--blue-500);
}
.date-cards-container .display-timings:hover {
  background-color: var(--blue-600);
}

/* date time picker */
.date-select-box select {
  height: 30px;
}

.card-no-shadow {
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
}

/* site details with map */

.display-site-details-map {
  height: 400px;
  width: 600px;
}
.testing-this-ok {
  min-height: 1000px;
}

.attendence-map-icons {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.attendence-map-icons:hover {
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
}
.attendence-map-icons img {
  height: 30%;
  width: 30%;
  flex: 1;
}

.attendence-page-style svg {
  margin-bottom: 3px;
}

/* task dashboard */
.task-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.task-dashboard .card-1 {
  min-width: 275px;
  /* overflow: hidden; */
}

.flex-1-style {
  flex: 1;
}
.task-dashboard .card-2 {
  min-width: 275px;
}
.task-dashboard .card-3 {
  min-width: 275px;
}
.task-dashboard .card-4 {
  min-width: 275px;
}
.task-dashboard .card-5 {
  grid-column: 5/6;
  grid-row: 1/3;
  min-width: 340px;
  height: 600px;
}
.task-dashboard .card-6 {
  grid-column: 1/5;
  min-height: 500px;
}

.task-dashboard-icon {
  border-radius: 0.375rem;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
}
.task-data-table table tbody tr {
  height: 3rem;
  text-transform: capitalize;
}
.task-data-table table tbody tr td {
  padding-bottom: 0.6rem;
  padding-top: 0.7rem;
}
.task-status-style {
  background-color: rgba(245, 125, 88, 0.8) !important;
  text-transform: capitalize;
}
.task-status-style-1 {
  background-color: rgba(255, 193, 7, 0.8) !important;
  text-transform: capitalize;
}
.task-status-style-2 {
  background-color: rgba(25, 135, 84, 0.8) !important;
  text-transform: capitalize;
}
.task-create-task {
  width: 50%;
}

/* Support Dashboard */
.support-dashboard-style {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.tictket-details-style {
  min-width: 550px;
}
.support-dashboard-style .card-1 {
  grid-column: 1/5;
  min-width: 600px;
  height: min-content;
  grid-row-end: span 5;
}
.support-dashboard-style .card-2 {
  grid-column: 5/6;
  min-width: 275px;
}
.support-dashboard-style .card-3 {
  grid-column: 5/6;
  min-width: 275px;
}
.support-dashboard-style .card-4 {
  grid-column: 5/6;
  min-width: 275px;
  min-height: 500px;
}

/* speech bubble for support ticket */
.bubble-style {
  position: relative;
  background: var(--blue-400);
  color: #ffffff;
  width: 60%;
  min-height: 40px;
  border-radius: 10px;
  padding: 15px;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.bubble-style:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 21px 26px 0 0;
  border-color: var(--blue-400) transparent transparent transparent;
  bottom: -21px;
  left: 25px;
  margin-left: -13px;
}

.bubble-style-2 {
  position: relative;
  background: rgba(25, 135, 84, 0.8);
  color: #ffffff;
  width: 60%;
  min-height: 40px;
  border-radius: 10px;
  padding: 15px;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.bubble-style-2:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 26px 21px 0;
  border-color: transparent rgba(25, 135, 84, 0.8) transparent transparent;
  bottom: -21px;
  right: 12px;
  margin-left: -13px;
}

/* pricing cards */
.pricing-cards {
  max-width: 350px;
  min-width: 350px !important;
  min-height: 600px;
}

.pricing-cards .list-unstyled {
  font-size: 20px;
  text-align: left;
  padding-left: 50px;
}

/* docs */
.docs-container {
  display: flex;
  min-height: 100%;
}

.docs-left-content {
  width: 300px;
  min-width: 300px;
  height: 100%;
  padding: 20px 20px 20px 0px;
  margin-right: 60px;
  border-right: 1px solid rgb(229, 233, 242);
}
.docs-right-content {
  padding: 20px 20px 20px 0px;
  min-height: 80vh;
}

.bd-callout-info {
  border-left-color: var(--blue-400) !important;
}
.bd-callout-alert {
  border-left-color: #d9534f !important;
}
.bd-callout-warning {
  border-left-color: #fff3cd !important;
}
.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #e9ecef;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

/* docs menu */
.docs-sub-menu-item {
  width: 100%;
  position: relative;
}
.docs-sub-menu-item a:nth-child(1) {
  color: rgb(99, 104, 110);
}
.docs-sub-menu-button {
  cursor: pointer;
  width: 100%;
  padding: 5px 0 5px 25px;
  display: flex;
  gap: 25px;
  justify-content: space-between;
}
.docs-sub-menu-button:hover {
  background-color: var(--panel-background);
  border-radius: 5px;
}
.docs-menu-text {
  font-size: 16px;
}
.docs-rotationg-icon {
  display: inline-block;

  transition: 0.1s all;
}
.docs-transition-add {
  transform: rotate(90deg);
}
.docs-left-content ul:nth-child(1) {
  padding-left: 0;
}
.docs-left-content ul {
  list-style: none;
  color: rgb(99, 104, 110);
}

.docs-sub-menu-items li a {
  list-style: none;
  color: rgb(132, 146, 166);
  padding: 8px;
}
.docs-sub-menu-items li a:hover {
  color: var(--blue-500);
}
.docs-sub-menu-items {
  width: 100%;
  transition: max-height 0.25s ease-in;
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.docs-sub-menu-expended {
  max-height: 200px;
}
.docs-sub-menu-collapsed {
  max-height: 0;
}

.docs-sub-right-content {
  width: 300px;
  min-width: 300px;
  height: 100%;
  padding: 20px 0px 20px 20px;
  margin-left: 60px;
  border-left: 1px solid rgb(229, 233, 242);
  font-size: 16px;
  color: rgb(99, 104, 110);
}
.docs-sub-right-content ul {
  list-style: none;
  padding-left: 0;
}
.docs-sub-right-content li a {
  list-style: none;
  color: rgb(132, 146, 166);
}
.docs-sub-right-content li a:hover {
  color: var(--blue-500);
}
.text-highlight {
  margin: 0px 3px;
  padding: 3px 5px;
  border-radius: 3px;
  background: rgb(239, 242, 247);
}

.docs-sub-left-content h2 {
  margin-top: 2.5rem;
}
.docs-image-lg {
  border-radius: 0.375rem;
  cursor: pointer;
  margin: 2rem 0 2rem 0;
  box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
}
.docs-image-lg img {
  border-radius: 0.375rem;
}

.docs-arrow {
  border: solid var(--blue-400);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  margin-right: 15px;
  margin-left: 5px;
}

@media screen and (max-width: 1450px) {
  .pricing-cards {
    max-width: 350px;
    min-width: 280px !important;
    min-height: 600px;
  }
}

@media screen and (max-width: 1250px) {
  .pricing-cards {
    max-width: 350px;
    min-width: 350px !important;
    min-height: 600px;
  }
  .dashboard-pricing-cards-container {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 900px) {
  .dashboard-plans-container {
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(226, 226, 226);
  }
  .AgencyDashboard_panel > div {
    min-width: 380px;
    /* width: min-content; */
  }
}
@media screen and (max-width: 480px) {
  /* floating sidebar on mobile */
  .closed {
    min-width: 0px !important;
  }
  .mobile-navigation-hamburger {
    display: block !important;
  }
  .closed .closebtn {
    top: -55px !important;
    right: -50px !important;
    font-size: 33px !important;
    border-radius: 0.375rem;
    padding: 0 10px 0 10px;
    /* background: var(--blue-400); */
    color: antiquewhite;
    z-index: 9;
    /* opacity: 0.6; */
  }
  .expanded .closebtn {
    top: -55px !important;
    left: 0px !important;
    font-size: 33px !important;
    border-radius: 0.375rem;
    padding: 0 10px 0 10px;
    margin-left: 0 !important;
    /* background: var(--blue-400); */
    color: antiquewhite;
    z-index: 9;
    /* opacity: 0.6; */
  }
  .header-logo {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .closed ul {
    display: none !important;
  }

  /* Dashboard  */
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
  .sites-card-header-2 {
    grid-column: 1;
  }
  .AgencyDashboard_panel > div {
    min-width: 100%;
    width: min-content;
  }

  /* profile */
  .displaysites-header {
    flex-direction: column;
    gap: 1rem;
  }
  .displaysites-header-btn-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /* tasks */
  .task-create-task {
    width: 100%;
  }
}
