.App {
}
.header-container {
  background-color: var(--blue-400);
  width: 100%;
  position: relative;
}
.hero-section {
  width: 100%;
  min-height: 80vh;
  position: relative;
  z-index: 29;
}
.hero-wave {
  color: white;
  position: absolute;
  bottom: 100;

  width: 100%;
}
.hero-wave svg {
  color: var(--blue-400);
}
.hero-wave .shape-fill {
  fill: var(--blue-400);
}

/* header-section */
.fixed-main-menu-section {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  opacity: 0.95;
  height: 80px;
  background-color: var(--blue-400);
  box-shadow: 0px 10px 20px 0px rgb(79 35 35 / 8%);
  -webkit-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
}
.fixed-main-menu-section .header-section {
  min-height: 80px;
}
@keyframes stickySlideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.main-menu-section {
  background-color: var(--blue-400);
  transition: all 0.3s ease-in-out;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.header-section {
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.header-section-scrolled {
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.scrolled {
  position: fixed;
  width: 100%;
  top: 0;
  left: auto;
  height: 90px;
  background-color: white;
  animation: scrolled 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  box-shadow: 0px 10px 20px 0px rgb(79 35 35 / 8%);
  z-index: 999;
}

@keyframes scrolled {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.menu-buttons-section {
  display: flex;
  align-items: center;
  gap: 5rem;
  color: antiquewhite;
  font-size: large;
  font-weight: 500;
  min-height: 70px;
  z-index: 999;
}
.menu-buttons-section .full-h-menu {
  min-height: 70px;
  display: flex;
  align-items: center;
}
.menu-buttons-section .full-h-menu a {
  min-height: 70px;
  display: flex;
  align-items: center;
}
.menu-buttons-section a {
  color: antiquewhite;
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer;
}
.main-dropbtn {
  cursor: pointer;
}

.main-dropdown {
  position: relative;
  min-height: 70px;
  display: flex;
  align-items: center;
}
.tip {
  opacity: 0;
  width: 1.25rem;
  height: 0;
  background-color: #fff;
  position: absolute;
  top: 77%;
  left: 35%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transition: opacity 0.15s ease-in;
}
.main-dropdown-content {
  position: absolute;
  top: 88%;
  left: -220px;
  background-color: white;
  width: min-content;
  max-height: 0;
  height: min-content; /*remove this */
  color: #2b2350;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0.375rem;

  transition: max-height 0.05s ease-out;
  display: flex;
  flex-direction: column;
}
.main-dropbtn-icon {
  margin-left: 2px;
}
.main-dropdown-content .div-menu-content {
  padding: 1rem 1.5rem;
}
.main-dropdown-content .div-heading {
  padding: 1.5rem 1.5rem 0.1rem calc(1.5rem + 10px);
}
.main-dropdown-content .div-menu-card {
  height: 95px;
  width: 320px;
  padding: 10px 20px 10px 10px;
  position: relative;
}
.main-dropdown-content a:hover {
  height: 95px;
}
.main-dropdown-content .div-menu-card:hover {
  background-color: #eaf5ff;
  border-radius: 0.375rem;
}
.main-dropdown-content .div-menu-card-icon-right {
  width: 200px;
}

.main-dropdown-content .div-card-direction-arrow {
  position: absolute;
  top: 28px;
  right: 15px;
  opacity: 0;
  transition: 0.25s;
}
.main-dropdown-content .div-menu-card:hover .div-card-direction-arrow {
  opacity: 1;
  right: 8px;
}

.main-dropdown-content .div-menu-card-header {
  margin-bottom: 3px;

  font-size: 15px;
  font-weight: bold;
}

.main-dropdown-content .div-menu-card-text {
  color: #6e6d7a;

  font-size: 14px;
  font-weight: 400;
}
.main-dropdown-content .div-menu-card-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  background-color: var(--blue-500);
  color: #fff;
  border-radius: 0.75rem;
}
.main-dropdown-content .div-menu-footer {
  background-color: #e9ecef;
  padding: 1.2rem 1.5rem 1.5rem calc(1.5rem + 10px);
}

.main-dropdown-content a {
  color: #2b2350;
  padding: 12px 16px;
  text-decoration: none;
  font-size: 16px;
  display: block;
}

.main-dropdown:hover .main-dropdown-content {
  /* border: 2px solid transparent; */
  max-height: 500px;
  transition: max-height 0.1s ease-in;
}
.main-dropdown:hover .tip {
  height: 1.25rem;
  opacity: 1;
}

/* main menu */
.sideNavbar {
  display: none;
}

.sidenav {
  height: 100vh;

  width: 0;

  position: fixed;

  z-index: 1;

  top: 0;

  right: 0;

  background-color: #fff;

  box-shadow: none;

  overflow-x: hidden;

  transition: 0.5s;

  padding-top: 60px;

  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.sidenav .closebtn {
  position: absolute;

  top: 10px;

  right: 25px;

  font-size: 36px;

  margin-left: 50px;
  color: var(--blue-500);
}

.main-mobile-sub-menu-item {
  font-size: 22px;
  text-decoration: none;
  color: var(--blue-500);
  transition: max-height 0.25s ease-in;
  overflow: hidden;
  max-height: 400px;
}
.main-mobile-sub-menu-item ul {
  font-size: 20px;
  text-decoration: none;
  list-style: none;
  color: var(--blue-500);
  padding-top: 10px;
  transition: all 0.25s ease-in;
}
.main-mobile-sub-menu-item ul li {
  padding: 10px 0;
}
.main-mobile-sub-menu-item .main-mobile-sub-menu-button {
  display: flex;
  justify-content: space-between;
}
.main-mobile-sub-menu-collapsed {
  max-height: 0;
  padding-top: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.main-mobile-sub-menu-expended {
  max-height: 400px !important;
}
.main-mobile-rotationg-icon {
  display: inline-block;
  transition: 0.25s all;
  font-size: 18px;
}
.main-mobile-transition-add {
  transform: rotate(90deg);
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 995px) {
  .sideNavbar {
    display: block;
    z-index: 999;
  }

  .sidenav .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    font-size: 22px;
  }
  .sidenav a {
    text-decoration: none;
    color: var(--blue-500);
  }
  .sidenav .nav a {
    text-decoration: none;
    color: var(--blue-500);
  }
  .menu-buttons-section {
    display: none;
  }
}

/* nav end */

.header-content-section {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: calc(12rem + 80px);
}
.header-content-section .left-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
.button-font {
  font-size: 22px;
}
.header-content-section .right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  perspective: 1500px;
}

/* utils */
.header-shaddow-button {
  background: #fff;
  box-shadow: 0px 20px 30px 0px rgba(50, 71, 116, 0.3);
  color: var(--blue-400);
  display: inline-block;
  padding: 7px 37px;

  border-radius: 0.375rem;

  border: 2px solid transparent;
  cursor: pointer;
}
.header-shaddow-button:hover {
  color: antiquewhite;
  background: var(--blue-700);
}

.shaddow-button {
  padding: 8px 30px;
  background: var(--blue-700);
  border-radius: 0.375rem;
  display: inline-block;

  color: #fff;

  box-shadow: 0px 20px 30px 0px rgba(50, 71, 116, 0.3);
  cursor: pointer;
  border: 1px solid var(--blue-700);
  transition: box-shadow 0.3s ease-in-out;
}

.shaddow-button:hover {
  box-shadow: none;
}

.shaddow-outline-button {
  border: 1px solid var(--blue-700);
  background: transparent;
  color: var(--blue-700);
  box-shadow: none;
  border-radius: 0.375rem;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.shaddow-outline-button:hover {
  background: var(--blue-700);
  color: #fff;
  box-shadow: 0px 20px 30px 0px rgba(50, 71, 116, 0.3);
}

.image-wrapper {
  margin-bottom: 35px;
  box-shadow: 0px 20px 30px 0px rgba(50, 71, 116, 0.3);
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

/*  */
.hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url(./uploads/Dashboard.jpg);
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;

  animation: zoom 4s infinite ease;
  animation-direction: alternate;
}
@keyframes zoom {
  from {
    -webkit-transform: scale(1) translate(0px) rotate3d(0);
  }
  to {
    -webkit-transform: scale(1.08) translate(0px)
      rotate3d(1.03942, -1.48145, 0, 10.3967deg);
  }
}
.hero-card:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
  animation: none;
}

.feature-container {
  width: 100%;
  position: relative;
  background-image: url(feature-backgound.svg);
  background-repeat: no-repeat;
  background-position: left;
  padding-bottom: 50px;
}
.feature-section {
  width: 100%;
  min-height: 80vh;
  margin-top: 10vh;
  color: #2b2350;
}
.feature-container-2 {
  width: 100%;
  background-image: url(feature-background-2.svg);
  background-repeat: no-repeat;
  background-position: right;
  overflow: hidden;
}
.phone-image-container {
  position: absolute;
  bottom: 5px;
  right: -50px;
  width: 175px;
  height: 300px;
  background-color: white;
  transition-duration: 300ms;
  border-radius: 15px;
  animation: horizontalflot 4s infinite ease;
  animation-direction: alternate;
}
.mobile-phone {
  margin: auto;
  margin-top: 0;
  padding: 5px 5px 15px;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 15px;
}

.screen {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 15px;
  overflow-y: auto;
  background-image: url(./uploads/app-drawer.jpg);
}
.task {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 15px;
  overflow-y: auto;
  background-image: url(./uploads/app-task.jpg);
}

.brove {
  width: 75px;
  height: 10px;
  background: white;
  position: absolute;
  margin: 0 50px;
  border-radius: 0 0 10px 10px;
}

.speaker {
  width: 30px;
  height: 3px;
  background: #d2d2d2;
  display: block;
  margin: auto;
  margin-top: 3px;
  border-radius: 10px;
}

@keyframes horizontalflot {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-15px);
  }
}

/* PHONE CONTAINERS */
.feature-phone-image-container {
  position: relative;
}

.phone-graphic {
  position: relative;
  width: 330px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.phone-case {
  box-shadow: inset 3px 3px 8px #82dcff, inset 0 0 3px #082049,
    inset -3px -3px 8px 0px #002094, 10px 10px 15px #b8b8b8;
  border-radius: 50px;
  background: #138ac9;
  width: 330px;
  padding: 10px;
}
.phone-case:before {
  content: "";
  position: absolute;
  background: var(--blue-400);
  width: 5px;
  height: 30px;
  left: -5px;
  top: 140px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: inset 4px 0px 2px #45b1ea;
}
.phone-case:after {
  content: "";
  position: absolute;
  background: var(--blue-400);
  width: 5px;
  height: 70px;
  left: 330px;
  top: 150px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 1px 0 4px #052152, inset -2px 3px 5px #2c9fdc,
    10px 10px 15px #b8b8b8;
}
.phone-container-outer {
  position: relative;
  font-family: helvetica;
  font-size: 0.8rem;
  border: 1px solid #8f8f8f;
  border-radius: 40px;
  box-shadow: inset 1px 1px 10px #ddd, inset 1px 1px 3px #8f8f8f,
    0 0 6px #1059a5;
  background-color: #000;
}
.phone-container-inner {
  position: relative;
  background-image: url(./uploads/app-dashboard.jpg);
  background-size: cover;
  margin: 13px;
  border-radius: 30px;
  overflow: hidden;
  height: 598px;
}
.phone-container-outer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 650px;
  background: linear-gradient(
    130deg,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0.2) 25%,
    rgba(255, 255, 255, 0) 25%
  );
  z-index: 5;
  border-radius: 40px;
}
.phone-container-inner:before {
  content: "";
  position: absolute;
  top: 0;
  background-color: #000;
  width: 110px;
  height: 17px;
  left: calc(50% - 55px);
  border-radius: 0 0 20px 20px;
  z-index: 2;
}

.feature-left-content {
  flex: 1;
}
.feature-right-content {
  flex: 1;
}
.floating-card-1 {
  min-height: min-content;
  min-width: min-content;
  position: absolute;
  top: 75px;
  right: 0;
  z-index: 49;
  background: none;
}
.landing-card-1 {
  height: 70px;
  width: 70px;
  background-color: white;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  color: var(--blue-500);
}
.floating-card-2 {
  min-height: min-content;
  min-width: min-content;
  position: absolute;
  top: 75px;
  left: 0;
  z-index: 49;
  background: none;
}

.floating-card-3 {
  min-height: min-content;
  min-width: min-content;
  position: absolute;
  bottom: 75px;
  right: 0;
  z-index: 49;
  background: none;
}

.floating-card-4 {
  min-height: min-content;
  min-width: min-content;
  position: absolute;
  bottom: 75px;
  left: 0;
  z-index: 49;
  background: none;
}

.list-check {
  color: var(--blue-500);
}
.feature2-right-content {
  flex: 1;
  height: 100%;
  width: 100%;
  position: relative;
}

.feature-2-image-container {
  height: min-content;
  width: min-content;

  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
}
.feature-2-image-container img {
  border-radius: 10px;
}

.feature-2-img-2 {
  top: -70px;
  right: -180px;
  position: absolute;
}
.feature-2-img-3 {
  bottom: -90px;
  right: -60px;
  position: absolute;
}

/* showcase */
.showcase-container {
  width: 100%;
  background-image: url(clip.svg);
  background-size: cover;
}
.showcase-section {
  color: white;
}
.showcase-menu {
  gap: 5rem;
  color: antiquewhite;
  font-size: large;
  font-weight: 500;
}

.showcase-buttons-container {
  width: 23.33%;
  min-width: 150px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcase-menu div span {
  cursor: pointer;
}

.showcase-images-container {
  width: 80%;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
}
.showcase-images-container img {
  border-radius: 10px;
}

.showcase-visible {
  display: block;
  animation: fadeInFromNoneShowcase 0.2s ease-in;
}
@keyframes fadeInFromNoneShowcase {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.showcase-hidden {
  display: none;
  opacity: 0;
}

/* testimonial section */
.testimonial-container {
  width: 100%;
}
.testimonial-section {
  min-height: 80vh;
}
.testimonials-section {
  width: 100%;
}
.testimonital-rightarrow {
  position: absolute;
  font-size: 26px;
  top: 45%;
  right: -10%;
  cursor: pointer;
}
.testimonital-leftarrow {
  position: absolute;
  font-size: 26px;
  top: 45%;
  left: -10%;
  cursor: pointer;
}
.testimonital-rightarrow:hover {
  color: var(--blue-400);
}
.testimonital-leftarrow:hover {
  color: var(--blue-400);
}
.slider__inner {
  position: relative;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  width: 70%;
  height: 450px;
  padding-left: 100px;
  padding-right: 100px;
  background-image: url(review-card-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.hidden {
  transform: translateX(100%);
}

quote {
  font-family: "Arial";
  font-weight: bold;
  font-size: 150px;
  color: var(--blue-400);
  margin-bottom: 0;
  line-height: 100px;
}

/* services */
.feature-box-1 {
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.feature-box-1 .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 2.4rem;
  background-color: var(--blue-500);
  color: #fff;
  border-radius: 0.75rem;
  cursor: default;
}

.feature-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Pricing */
.pricing-container {
  width: 100%;
  background-image: url(price-background.svg), url(price-background-right.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: left, right;
  overflow: hidden;
}
.pricing-section {
  min-height: 100vh;
}
.pricings-section {
  width: 100%;
}

/* FAQ */
.faq-container {
  width: 100%;
}
.faqq-section {
  min-height: 80vh;
}
.faqs-section {
  width: 100%;
}
.faq-sub-container .accordion-button {
  font-size: 1.5rem;
}

.faq-sub-container {
  width: 80%;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 0.375rem;
}

/* CTA */
.cta-container {
  width: 100%;
  background-color: var(--blue-400);
  background-image: url(cta-background.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.cta-section {
  min-height: 50vh;
  color: white;
}
.ctas-section {
  width: 60%;
}

/* Footer */
.footer-container {
  width: 100%;
  background-color: white;
}
.footer-section {
  min-height: 300px;
}
.footers-section {
  width: 100%;
}

.get-on-play-store {
  margin-top: 25px;
}
.get-on-play-store-logo {
  display: flex;
  align-items: center;
  gap: 1.4rem;
  margin-top: 1rem;
}
.get-on-google-play-text {
  font-size: 13px;
}

.privacypolicy-header {
  background-color: var(--blue-400);
}
.privacypolicy-header a {
  text-decoration: none;
}

.custom-homapge-modal .modal-content {
  padding: 30px;
}

/* break points */
@media screen and (max-width: 1850px) {
  .hero-card {
    height: 390px;
    width: 600px;
  }
  .phone-image-container {
    width: 150px;
    height: 275px;
  }
  .brove {
    width: 50px;
  }
  .speaker {
    width: 25px;
  }
  .feature-2-img-2 {
    top: -70px;
    right: -30px;
  }
  .feature-2-img-3 {
    bottom: -90px;
    right: -30px;
  }
}
@media screen and (max-width: 1450px) {
  .hero-card {
    height: 350px;
    width: 550px;
  }
  .phone-image-container {
    width: 125px;
    height: 225px;
  }
  .brove {
    width: 25px;
  }
  .speaker {
    width: 20px;
  }
  .showcase-menu .showcase-buttons {
    flex-wrap: wrap;
  }
  .testimonials-section > div:nth-of-type(1) {
    margin-top: 10px !important;
  }
  .pricing-cards-container {
    gap: 1rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .hero-card {
    height: 300px;
    width: 500px;
  }
  .phone-image-container {
    width: 100px;
    height: 200px;
  }
  .brove {
    width: 10px;
    margin: 0 40px;
  }
  .speaker {
    width: 15px;
  }
  .feature-2-img-1 img {
    height: 350px;
    width: 550px;
  }
  .feature-2-img-2 img {
    height: 170px;

    width: 300px;
  }
  .feature-2-img-3 img {
    height: 240px;

    width: 183px;
  }
  .pricing-cards-container {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 992px) {
  .fixed-main-menu-section {
    height: 60px;
  }
  .fixed-main-menu-section .header-section {
    min-height: 60px;
  }
  .header-content-section {
    flex-direction: column;
    padding-top: calc(4rem + 100px);
    gap: 3rem;
  }
  .hero-section {
    padding-bottom: 40px;
  }
  .get-on-play-store-logo {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.4rem;
  }
  .get-on-play-store-logo :nth-child(2) {
    margin-left: -10px;
  }
  .ctas-section {
    width: 80%;
  }
  .feature-section-content {
    flex-direction: column;
    gap: 3rem !important;
  }
  .feature2-section-content {
    flex-direction: column-reverse;
    gap: 3rem !important;
  }
  .feature-2-img-2 {
    top: -20px;
    right: -20px;
  }
  .feature-2-img-3 {
    bottom: -20px;
    right: -20px;
  }
  .slider__inner {
    height: 450px;
    padding-left: 10px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 700px) {
  .header-content-section {
    padding-top: calc(1rem + 100px);
  }
  .hero-section {
    overflow: hidden;
  }
  .header-content-section .right-content {
    width: 100%;
  }
  .hero-card {
    width: 100%;
  }
  .phone-image-container {
    position: absolute;
    bottom: 5px;
    right: -10px;
  }

  .landing-card-1 {
    height: 50px;
    width: 50px;
    font-size: 36px;
  }
  .feature-2-img-1 {
    width: 100%;
  }
  .feature-2-img-1 img {
    width: 100%;
  }
  .feature-2-img-2 {
    top: -60px;
    right: -20px;
  }
  .feature-2-img-3 img {
    height: 160px;

    width: 100px;
  }

  .showcase-images-container {
    width: 100%;
  }
  .faq-sub-container {
    width: 100% !important;
  }
  .footers-section > div:nth-of-type(1) {
    flex-direction: column-reverse;
  }
  .footers-section > div > div:nth-of-type(4) > form > div {
    flex-direction: column;
  }
  .footers-section > div > div:nth-of-type(4) {
    margin: 0 !important;
  }
  .footers-section > div > div {
    width: 100% !important;
  }

  @keyframes zoom {
    from {
      -webkit-transform: scale(1);
    }
    to {
      -webkit-transform: scale(1.05);
    }
  }
  @keyframes horizontalflot {
    from {
      -webkit-transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-5px);
    }
  }
}
@media screen and (max-width: 500px) {
  .top-navbar-icon-style {
    display: none;
  }
  .floating-card-1,
  .floating-card-2,
  .floating-card-3,
  .floating-card-4 {
    display: none;
  }
  .slider__inner {
    width: 100%;
    height: 480px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .ctas-section {
    width: 90%;
  }
  .testimonital-rightarrow {
    font-size: 26px;
    top: 45%;
    right: -10px;
  }
  .testimonital-leftarrow {
    font-size: 26px;
    top: 45%;
    left: -10px;
  }
  .feature-box-1 {
    align-items: center;
  }

  .feature-content {
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 435px) {
  .shaddow-button {
    padding: 10px 30px !important;
  }
  .shaddow-outline-button {
    padding: 10px 30px !important;
  }
  .button-font {
    font-size: 18px;
  }
  .pricing-cards {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
