.sidebar-container {
  background-color: var(--blue-400);
  width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: antiquewhite;
  position: relative;
  transition: all 0.1s ease-in-out;
  /* overflow: hidden; */
  box-shadow: 4px 0 3.94px 0.06px rgb(4 26 55 / 16%);
  min-height: 100vh; /* ****************remove this later **************** */
}
.expanded {
  min-width: 250px;
}
.closed {
  min-width: 70px;
}

.closed .menu-text {
  display: none;
}
.closed .username {
  visibility: hidden;
}

.sidebar-container .closebtn {
  position: absolute;

  top: 0px;

  right: 20px;

  font-size: 32px;

  margin-left: 50px;
  cursor: pointer;
}

.sidebar-container .username {
  font-size: 22px;
  padding: 10px;
  color: var(--primary-color);
  background-color: var(--blue-500);
  width: 100%;
  text-align: center;
  text-transform: capitalize;
}
.sidebar-container ul {
  font-size: 18px;
  text-decoration: none;
  list-style: none;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding-left: 0;
  width: 100%;
  height: auto;
}

.sidebar-container ul li a {
  color: antiquewhite;
}
.sidebar-container ul a {
  color: antiquewhite;
  text-decoration: none;
}
.sidebar-container .menu-item {
  cursor: pointer;
  width: 100%;
  padding: 5px 0 5px 22px;
}
.sidebar-container .menu-icon {
  font-size: 24px;
  display: flex;
  align-items: center;
}
.sidebar-container .menu-item a {
  display: flex;
  align-items: center;
  gap: 25px;
}

.sidebar-container .menu-item:hover {
  background-color: var(--blue-500);
  transition: all 250ms;
}
.sidebar-container .sub-menu-items li:hover {
  background-color: var(--blue-500);
  transition: all 250ms;
}

/* SUB MENU */
.sub-menu-item {
  width: 100%;
  position: relative;
}
.sub-menu-item .sub-menu-button {
  cursor: pointer;
  width: 100%;
  padding: 5px 0 5px 22px;
  display: flex;
  gap: 25px;
}
.sub-menu-item .sub-menu-button:hover {
  background-color: var(--blue-500);
  transition: all 250ms;
}

.sub-menu-item .sub-menu-items {
  width: 100%;
  transition: max-height 0.25s ease-in;
  overflow: hidden;
}

.sub-menu-item .sub-menu-items li {
  width: 100%;
  display: flex;
  gap: 25px;
  /* padding-left: 40px; */
  cursor: pointer;
}
.sub-menu-item .sub-menu-items li:first-child {
  margin-top: 10px;
}
.sub-menu-item .sub-menu-items li a {
  width: 100%;
  display: flex;
  gap: 25px;
  padding-left: 40px;
  cursor: pointer;
}
.sub-menu-item .sub-menu-items li:hover {
  background-color: var(--blue-500);
  transition: all 250ms;
}

.sub-menu-expended {
  max-height: 120px;
}
.sub-menu-collapsed {
  padding: 0;
  max-height: 0;
  position: relative;
}

/* sub-menu-items-when-collapsed */

/* .closed .sub-menu-collapsed:hover {
    
    position: relative;
} */
.closed .sub-menu-items {
  position: absolute;
  top: 0;
  left: 70px;
  background-color: var(--blue-400);
  z-index: -1;
}

.trsnition-add {
  display: inline-block;
  transition: 0.2s all;
}
.trsnition-add svg {
  margin-bottom: 3px;
}
.rotationg-icon {
  display: inline-block;
  transform: rotate(90deg);
}

.sub-menu-button-collapsed:hover + ul {
  overflow: visible;
  max-height: 500px;
  padding: 5px 20px 15px 0px;
  z-index: 11;
  width: 200px;
}
.sub-menu-button-collapsed:hover + ul li a > .menu-text {
  display: flex;
}

.sub-menu-button-collapsed + ul:hover {
  overflow: visible;
  max-height: 500px;
  padding: 5px 0px 15px 0px;
  z-index: 11;
  width: 200px;
}

.sub-menu-button-collapsed + ul li a > .menu-text {
  display: flex;
}

/* upgrade plan notify */
.expanded .upgrade-plan {
  color: antiquewhite;
  font-size: 18px;
  margin: 2rem 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-width: 1px 0 0 0;
  border-color: aliceblue;
  text-align: center;
  border-style: solid;
}
.closed .upgrade-plan {
  display: none;
}
