.invoice{
    font-family: Nunito,sans-serif !important;
    font-size: 14px;
    color: #555;
    padding: 0;
    margin: 0;
}
.invoice-wrapper{
    position: relative;
    background: #fff;
    padding: 40px 35px;
    box-shadow: 0 0 17px 0 rgb(16 40 73 / 9%);
    width: 700px;
}
.invoice-wrapper .input, .select, .span {
    display: inline-block;
    width: 98%;
    padding: 4px 12px 4px 0;
    margin-bottom: 1px;
    border: 1px dotted transparent;
    transition: background .1s ease-in-out,border-color .2s ease-in-out;
    font-family: Nunito,sans-serif;
    font-size: 14px;
    color: #555;
    background: transparent;
    border-radius: 3px;
    outline: 0;
}
.invoice-wrapper .input:hover{
    background: #f0efef;
}
.invoice-wrapper .input:focus{
    background: #f0efef;
    border-color: #e3e3e3;
}
.invoice-wrapper .xwhite:hover{
    color: inherit;
}
.invoice-wrapper .xwhite:focus{
    color: inherit;
}
.invoice-wrapper .xp-5 {
    padding: 5px;
}
.invoice-wrapper .xfs-20 {
    font-size: 20px;
}
.invoice-wrapper .xfs-30 {
    font-size: 30px;
}
.invoice-wrapper .xfs-12 {
    font-size: 12px;
}

.invoice-wrapper .xbold {
    font-weight: 600;
}
.invoice-wrapper .xfs-45 {
    font-size: 45px;
}
.invoice-wrapper .xrow {
    position: relative;
    border-bottom: 1px solid #e3e3e3;
}
.invoice-wrapper .xw-17 {
    width: 17%;
}
.invoice-wrapper .xw-16 {
    width: 16%;
}
.invoice-wrapper .xw-18 {
    width: 18%;
}
.invoice-wrapper .xw-55 {
    width: 55%;
}
.invoice-wrapper .xw-50 {
    width: 50%;
}
.invoice-wrapper .xw-45 {
    width: 45%;
}
.invoice-wrapper .xw-40 {
    width: 40%;
}
.invoice-wrapper .xw-60 {
    width: 60%;
}
.invoice-wrapper .xw-48 {
    width: 48%;
}
.invoice-wrapper .xw-100 {
    width: 100%;
}
.invoice-wrapper .xw-auto {
    width: auto !important ;
}
.invoice-wrapper .xp-4-8 {
    padding: 4px 8px;
}
.invoice-wrapper .xp-4-1 {
    padding: 4px 2px;
}

.invoice-wrapper .xright {
    text-align: right;
}
.invoice-wrapper .xmt-10 {
    margin-top: 10px;
}
.invoice-wrapper .xmt-20 {
    margin-top: 20px;
}
.invoice-wrapper .xmt-40 {
    margin-top: 40px;
}
.invoice-wrapper .xmt-30 {
    margin-top: 30px;
}
.invoice-wrapper .xml-30 {
    margin-left: 30px;
}

.invoice-wrapper .xdark {
    color: #222;
}
.invoice-wrapper .xbg-dark {
    background-color: #666;
}
.invoice-wrapper .xbg-gray {
    background-color: #e3e3e3;
}
.invoice-wrapper .xwhite {
    color: #fff;
}
.invoice-wrapper .xmb-5 {
    margin-bottom: 5px;
}
.row__remove {
    display: flex;
    padding: 0;
    position: absolute;
    top: 10px;
    right: -20px;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s ease-in-out;
}
.row-hidden-input {
    opacity: 0;
    transition: opacity .2s ease-in-out;
}
.invoice-wrapper .xrow:hover  .row__remove{
    opacity: 1;
}
.invoice-wrapper .xrow:hover  .row-hidden-input{
    opacity: 1;
}
.icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    border: 0;
    cursor: pointer;
  }
  .icon-absolute {
    position: absolute;
  }
  .icon-remove::after{
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    left: 7px;
    top: 3px;
    background-color: #fff;
  }
  .icon-remove::before{
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    left: 7px;
    top: 3px;
    background-color: #fff;
  }
  .icon-remove::after{
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    left: 7px;
    top: 3px;
    background-color: #fff;
  }
  .icon-remove::before{
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    left: 7px;
    top: 3px;
    background-color: #fff;
  }