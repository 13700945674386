.schedulingfront-hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url(./uploads/schedule-1.jpg);
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}
.attendancefront-hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url(./uploads/attendance-3.jpg);
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}
.reportsfront-hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url(./uploads/reports-1.jpg);
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}
.tasksfront-hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url("./uploads/tasks.jpg");
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}
.empmgmtfront-hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url("./uploads/empmgmt1.jpg");
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}
.supportfront-hero-card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;

  width: 660px;
  height: 435px;
  box-shadow: 0 5px 20px 5px #00000044;

  border-radius: 10px;
  background-image: url("./uploads/support.jpg");
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}

.feature-main-image-container {
  width: 560px;
  height: 400px;

  border-radius: 10px;
  position: relative;
}
.feature1-attendance-main-image-container {
  width: 560px;
  height: 259px;

  border-radius: 10px;
  position: relative;
}
.feature-main-image-itself {
  width: 560px;
  height: 400px;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflot 3s infinite ease-in-out;
  animation-direction: alternate;
}
.feature1-attendance-main-image-itself {
  width: 560px;
  height: 259px;

  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflot 3s infinite ease-in-out;
  animation-direction: alternate;
}
.feature-main-image-itself-op {
  width: 560px;
  height: 400px;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}
.feature-main-image-with-icon-container {
  width: 420px;
  height: 300px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-main-image-with-icon {
  width: 170px;
  height: 170px;
  position: relative;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-500);
}
.feature-main-image-with-icon svg {
  font-size: 98px;
  color: #fff;
}
.tasks1-main-image-floating {
  width: 332px;
  height: 53px;
  position: absolute;
  top: -20px;
  right: 0;
  background-image: url(./uploads/tasks2.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}
.tasks2-main-image-floating {
  width: 422px;
  height: 53px;
  position: absolute;
  bottom: -20px;
  left: 0;
  background-image: url(./uploads/tasks3.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 2.5s infinite ease-in-out;
  animation-direction: alternate;
}
.tasks3-main-image-floating {
  width: 160px;
  height: 300px;
  position: absolute;
  bottom: -20px;
  left: 0;
  background-image: url(./uploads/app-task.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}
.reports-main-image-floating {
  width: 436px;
  height: 400px;
  position: absolute;
  top: -70px;
  right: -70px;
  background-image: url(./uploads/reports-5.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}
.reports3-main-image-floating {
  width: 536px;
  height: 254px;
  position: absolute;
  bottom: -70px;
  right: -10px;
  background-image: url(./uploads/reports-7.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}
.empmgmt1-main-image-floating {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-500);
  box-shadow: 0 5px 20px 5px #00000044;
  animation: horizontalflot 2.5s infinite ease-in-out;
  animation-direction: alternate;
}
.empmgmt2-main-image-floating {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -20px;
  left: -20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-500);
  box-shadow: 0 5px 20px 5px #00000044;
  animation: horizontalflotop 2.5s infinite ease-in-out;
  animation-direction: alternate;
}
.empmgmt1-main-image-floating svg {
  font-size: 50px;
  color: #fff;
}
.empmgmt2-main-image-floating svg {
  font-size: 50px;
  color: #fff;
}

.support1-main-image-floating {
  width: 300px;
  height: 80px;
  position: absolute;
  top: -20px;
  left: -20px;
  border-radius: 10px;
  box-shadow: 0 5px 20px 5px #00000044;
  animation: horizontalflot 2.5s infinite ease-in-out;
  animation-direction: alternate;
}
.support2-main-image-floating {
  width: 300px;
  height: 80px;
  position: absolute;
  border-radius: 10px;
  bottom: -20px;
  right: -20px;
  box-shadow: 0 5px 20px 5px #00000044;
  animation: horizontalflotop 2.5s infinite ease-in-out;
  animation-direction: alternate;
}

/* speech bubble for support ticket */
.front-bubble-style {
  position: relative;
  background: var(--blue-400);
  color: #ffffff;
  width: 100%;
  min-height: 40px;
  border-radius: 10px;
  padding: 15px;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.front-bubble-style:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 21px 26px 0 0;
  border-color: var(--blue-400) transparent transparent transparent;
  bottom: -21px;
  left: 25px;
  margin-left: -13px;
}

.front-bubble-style-2 {
  position: relative;
  background: rgba(25, 135, 84, 0.8);
  color: #ffffff;
  width: 100%;
  min-height: 40px;
  border-radius: 10px;
  padding: 15px;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.front-bubble-style-2:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 26px 21px 0;
  border-color: transparent rgba(25, 135, 84, 0.8) transparent transparent;
  bottom: -21px;
  right: 12px;
  margin-left: -13px;
}

.feature-main-image-with-icon .bottom-left-floating-image {
  width: 155px;
  height: 57px;
  position: absolute;
  bottom: -30px;
  left: -100px;
  background-image: url(./uploads/schedule-4.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflot 3s infinite ease-in-out;
  animation-direction: alternate;
}
.feature-main-image-with-icon .top-right-floating-image {
  width: 243px;
  height: 57px;
  position: absolute;
  top: -30px;
  right: -170px;
  background-image: url(./uploads/schedule-3.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}

.feature-main-image-with-icon .bottom-right-floating-image {
  width: 402px;
  height: 100px;
  position: absolute;
  bottom: -80px;
  right: -180px;
  background-image: url(./uploads/schedule-9.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflot 3s infinite ease-in-out;
  animation-direction: alternate;
}
.feature-main-image-with-icon .top-left-floating-image {
  width: 336px;
  height: 106px;
  position: absolute;
  top: -80px;
  left: -170px;
  background-image: url(./uploads/schedule-8.jpg);
  background-size: cover;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}

@keyframes horizontalflot {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-20px);
  }
}
@keyframes horizontalflotop {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(20px);
  }
}

.feature-main-image-top-floating {
  width: 500px;
  height: 208px;
  position: absolute;
  bottom: -20px;
  right: -20px;
  background-image: url(./uploads/schedule-7.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}
.bottom-right-floating-patter {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: -40px;
  right: -40px;
  background-image: url(./bgpattern.svg);
  background-size: contain;
  z-index: -1;
}
.top-left-floating-patter {
  width: 200px;
  height: 200px;
  position: absolute;
  top: -40px;
  left: -40px;
  background-image: url(./bgpattern.svg);
  background-size: contain;
  z-index: -1;
}
.bottom-left-floating-patter {
  width: 200px;
  height: 200px;
  position: absolute;
  bottom: -40px;
  left: -40px;
  background-image: url(./bgpattern.svg);
  background-size: contain;
  z-index: -1;
}
.top-right-floating-patter {
  width: 200px;
  height: 200px;
  position: absolute;
  top: -40px;
  right: -40px;
  background-image: url(./bgpattern.svg);
  background-size: contain;
  z-index: -1;
}

.feature-box-front {
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.feature-box-front a {
  text-decoration: none;
  color: inherit;
}
.feature-box-front .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  background-color: var(--blue-500);
  color: #fff;
  border-radius: 0.75rem;
}

.feature4-attendance-main-image-container {
  width: 560px;
  height: 295px;

  border-radius: 10px;
  position: relative;
}
.feature4-attendance-main-image-itself {
  width: 560px;
  height: 295px;

  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflot 3s infinite ease-in-out;
  animation-direction: alternate;
}
.feature4-attendance-main-image-top-floating {
  width: 519px;
  height: 81px;
  position: absolute;
  bottom: -20px;
  right: -20px;
  background-image: url(./uploads/attendance-1.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px 5px #00000044;
  border-radius: 10px;
  animation: horizontalflotop 3s infinite ease-in-out;
  animation-direction: alternate;
}

/* break points */
@media screen and (max-width: 1850px) {
  .hero-card {
    height: 390px;
    width: 600px;
  }
  .schedulingfront-hero-card {
    height: 390px;
    width: 600px;
  }
  .attendancefront-hero-card {
    height: 390px;
    width: 600px;
  }
  .reportsfront-hero-card {
    height: 390px;
    width: 600px;
  }
  .tasksfront-hero-card {
    height: 390px;
    width: 600px;
  }
  .empmgmtfront-hero-card {
    height: 390px;
    width: 600px;
  }
  .supportfront-hero-card {
    height: 390px;
    width: 600px;
  }
}
@media screen and (max-width: 1450px) {
  .hero-card {
    height: 350px;
    width: 550px;
  }
  .schedulingfront-hero-card {
    height: 350px;
    width: 550px;
  }
  .attendancefront-hero-card {
    height: 350px;
    width: 550px;
  }
  .reportsfront-hero-card {
    height: 350px;
    width: 550px;
  }
  .tasksfront-hero-card {
    height: 350px;
    width: 550px;
  }
  .empmgmtfront-hero-card {
    height: 350px;
    width: 550px;
  }
  .supportfront-hero-card {
    height: 350px;
    width: 550px;
  }

  .feature-main-image-container {
    height: 350px;
    width: 550px;
  }
  .feature1-attendance-main-image-container {
    height: 350px;
    width: 550px;
  }
  .feature-main-image-itself {
    height: 350px;
    width: 550px;
  }
  .feature1-attendance-main-image-itself {
    height: auto;
    width: 550px;
  }
  .feature-main-image-itself-op {
    height: 350px;
    width: 550px;
  }
  .reports-main-image-floating {
    width: 436px;
    height: 400px;
    top: -70px;
    right: -20px;
    background-size: cover;
  }

  .reports3-main-image-floating {
    width: 436px;
    height: 200px;
    position: absolute;
    bottom: -70px;
    right: 10px;
  }

  .feature4-attendance-main-image-container {
    height: 350px;
    width: 550px;
  }
  .feature4-attendance-main-image-itself {
    height: auto;
    width: 550px;
  }
  .feature4-attendance-main-image-top-floating {
    width: 450px;
  }
}
@media screen and (max-width: 1200px) {
  .hero-card {
    height: 300px;
    width: 500px;
  }
  .schedulingfront-hero-card {
    height: 300px;
    width: 500px;
  }
  .attendancefront-hero-card {
    height: 300px;
    width: 500px;
  }
  .reportsfront-hero-card {
    height: 300px;
    width: 500px;
  }
  .tasksfront-hero-card {
    height: 300px;
    width: 500px;
  }
  .empmgmtfront-hero-card {
    height: 300px;
    width: 500px;
  }
  .supportfront-hero-card {
    height: 300px;
    width: 500px;
  }

  .feature-main-image-itself-op {
    height: 300px;
    width: 500px;
  }
  .reports-main-image-floating {
    width: 316px;
    height: 300px;
    top: -70px;
    right: -20px;
    background-size: cover;
  }
  .reports3-main-image-floating {
    width: 436px;
    height: 200px;
    position: absolute;
    bottom: -10px;
    right: 10px;
  }
  .feature-main-image-container {
    height: 300px;
    width: 500px;
  }
  .feature1-attendance-main-image-container {
    height: 300px;
    width: 500px;
  }
  .feature1-attendance-main-image-itself {
    height: auto;
    width: 500px;
  }
  .feature-main-image-itself {
    height: 300px;
    width: 500px;
  }
  .tasks1-main-image-floating {
    right: 40px;
  }
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 700px) {
  .hero-card {
    width: 100%;
  }
  .schedulingfront-hero-card {
    width: calc(100% - 20px);
  }
  .attendancefront-hero-card {
    width: calc(100% - 20px);
  }
  .reportsfront-hero-card {
    width: calc(100% - 20px);
  }
  .tasksfront-hero-card {
    width: calc(100% - 20px);
  }
  .empmgmtfront-hero-card {
    width: calc(100% - 20px);
  }
  .supportfront-hero-card {
    width: calc(100% - 20px);
  }
  .feature-main-image-container {
    width: calc(100% - 20px);
  }
  .feature1-attendance-main-image-container {
    width: calc(100% - 20px);
    height: auto;
  }
  .feature1-attendance-main-image-itself {
    height: auto;
    width: calc(100% - 20px);
  }
  .feature-main-image-itself-op {
    height: auto;
    width: calc(100% - 20px);
  }
  .reports-main-image-floating {
    width: 216px;
    height: 200px;
    top: -70px;
    right: 20px;
    background-size: cover;
  }
  .reports3-main-image-floating {
    width: calc(100% - 20px);
    height: 100px;
    position: absolute;
    bottom: -10px;
    right: 10px;
  }
  .feature4-attendance-main-image-container {
    height: auto;
    width: calc(100% - 20px);
  }
  .feature4-attendance-main-image-itself {
    width: calc(100% - 20px);
    height: auto;
  }
  .feature4-attendance-main-image-top-floating {
    background-size: cover;
    width: calc(100% - 40px);

    right: 20px;
  }
  .feature-main-image-itself {
    width: calc(100% - 20px);
  }
  .feature-main-image-with-icon-container {
    width: 170px;
  }
  .feature-main-image-with-icon .bottom-left-floating-image {
    left: -50px;
  }
  .feature-main-image-with-icon .top-right-floating-image {
    right: -50px;
  }
  .feature-main-image-with-icon .bottom-right-floating-image {
    right: -70px;
    width: 280px;
    height: 70px;
  }
  .feature-main-image-with-icon .top-left-floating-image {
    left: -70px;
    width: 280px;
    height: 90px;
  }
  .feature-main-image-top-floating {
    width: calc(100% - 20px);
    background-size: cover;
    right: 20px;
  }
  .bottom-left-floating-patter {
    width: 150px;
    height: 150px;

    bottom: -20px;
    left: -20px;
  }
  .top-right-floating-patter {
    width: 150px;
    height: 150px;

    top: -20px;
    right: -20px;
  }
  .support1-main-image-floating {
    top: -20px;
    left: 20px;
  }
  .support2-main-image-floating {
    bottom: -20px;
    right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .feature-box-front a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tasks1-main-image-floating {
    width: 232px;
    height: 38px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .tasks2-main-image-floating {
    width: 280px;
    height: 34px;
  }
  .empmgmt1-main-image-floating {
    bottom: 40px;
  }
}
@media screen and (max-width: 435px) {
}
